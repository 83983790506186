<template>
    <div class="page padd30">
        <div class="search_wap">
            <div class="a-inline-block">
                <a-input-search v-model="key" class="sInput" placeholder="根据任务描述搜索" @search="onSearch"/>
            </div>
        </div>
        <div v-infinite-scroll="getTask" infinite-scroll-disabled="dataLoadStop" infinite-scroll-distance="50">
            <div class="lists" v-for="(item,index) in data" :key="index">
                <div class="list_row">
                    <div class="flex">
                        <div><label for="">任务类型：</label>{{item.type}}</div>
                        <div><label for="">创建人：</label>{{item.creater_uid}}</div>
                    </div>
                    <div><label for="">任务状态：</label>{{item.status}}</div>
                    <div>
                        <label for="">任务描述：</label>{{item.task_content}}
                    </div>
                    <div><label for="">开始时间：</label>{{item.start}}</div>
                    <div><label for="">结束时间：</label>{{item.end}}</div>
                </div>
                <div class="btn_con">
                    <a-button type="primary" size="small" ghost @click="showDetail(item.id)">查看</a-button>
                    <a-button type="primary" size="small" ghost @click="editTask(item.id)">编辑</a-button>
                    <a-popconfirm title="确定要关闭吗?"  @confirm="() => off(item.id)" v-if="item.status != '取消'">
                        <a-button type="danger" size="small" ghost>关闭</a-button>
                    </a-popconfirm>
                    <a-popconfirm title="确定要删除吗?" @confirm="() => onDelete(item.id)">
                        <a-button type="danger" size="small" ghost>删除</a-button>
                    </a-popconfirm>
                </div>
            </div>
            <div class="list-load-end" style="text-align:center;padding-top:25px;padding-bottom:50px">
                <span>{{dataLoadNomore ? "--没有更多数据了--" : "加载中..."}}</span>
            </div>
        </div>
        
    </div>
</template>
<script>
// import $ from "jquery";
import infiniteScroll from "vue-infinite-scroll";
import '../../../assets/css/wapList.css';
import {requestXml,getDateTime} from '../../../assets/js/request';
export default {
    directives: {infiniteScroll},
    data() {
        return {
            // 搜索的内容
            key: "",
            data: [],
            taskType: ["打电话","发短信","发微信","发邮件","分享文章","分享表单","分享文件","分享商品","客户拜访","其他"],
            taskStatus: ["取消","新建","跟进中","完成"],

             // 分页
            dataLoadStop: false,  //列表数据加载停止状态
            dataLoadNomore: false,    //列表数据加载是否完成
            pagination: {
                current: 1,
                total: 100,
                pageSize: 10, // 默认每页显示数量
                showSizeChanger: true, // 显示可改变每页数量
                pageSizeOptions: ['10', '20', '50', '100'], // 每页数量选项
                showQuickJumper: true,
                // showTotal: total => `Total ${total} items`, // 显示总数
                onChange: (current) => this.pagesChange(current), //点击当前页码
                onShowSizeChange: (current, pageSize) => {
                    //设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                    console.log(pageSize);
                    this.showSizeChange(current, pageSize);
                },
            },
        };
    },
    mounted: function(){
        this.getTask();
    },
    methods:{
        // 获取任务列表
        getTask() {
            this.dataLoadStop = true;
            requestXml("/scrm/Task/getMyList","POST",(res) => {
                for(let i=0;i<res.list.length;i++){
                    res.list[i].start = getDateTime(res.list[i].start);
                    res.list[i].end = getDateTime(res.list[i].end);
                    
                    res.list[i].status = this.taskStatus[Number(res.list[i].status)];
                    res.list[i].type = this.taskType[Number(res.list[i].type-1)]
                }
                this.data = this.data.concat(res.list);
                if(res.page.maxpage>this.pagination.current){
                    this.pagination.current += 1
                    this.dataLoadStop = false
                }else{
                    this.dataLoadNomore = true
                }
                this.pagination.total = Number(res.page.total);
                this.pagination.pageSize = Number(res.page.pagesize);
            },{"form":"mycreated","content": this.key,"page":{"curpage":this.pagination.current,"pagesize":this.pagination.pageSize}})
        },
        // 搜索
        onSearch(){
            this.data=[];
            this.dataLoadNomore=false;
            this.pagination.current=1;
            this.getTask();
        },
        // 查看
        showDetail(id){
            this.$router.push({
                path: "/scrm_wap/task/taskDetail",
                query: {
                    id: id,
                }
            });
        },
        // 编辑
        editTask(id){
            this.$router.push({
                path: "/scrm_wap/task/taskAdd",
                query: {
                    id: id,
                }
            });
        },
        // 关闭
        off(id){
            requestXml("/scrm/Task/setClose","POST",(res) => {
                if(res=="success"){
                    this.data=[];
                    this.getTask();
                }
            },{"id": id})
        },
        // 删除
        onDelete(id){
            requestXml("/scrm/Task/del","POST",(res) => {
                if(res=="success"){
                    this.data=[];
                    this.getTask();
                }
            },{"id": id})
        }
        
    }
};
</script>
<style>
     

    
</style>
